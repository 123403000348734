import { TweenMax, Sine } from "gsap";

function random(min, max) {
  const delta = max - min;
  return (direction = 1) => min + delta * Math.random() * direction;
}

function moveX(target, direction) {
  TweenMax.to(target, randomTime(), {
    x: randomX(direction),
    ease: Sine.easeInOut,
    yoyo: true,
    repeat: 1,
    onComplete: moveX,
    onCompleteParams: [target, direction * -1],
  });
}

function moveY(target, direction) {
  TweenMax.to(target, randomTime(), {
    y: randomY(direction),
    ease: Sine.easeInOut,
    yoyo: true,
    repeat: 1,
    onComplete: moveY,
    onCompleteParams: [target, direction * -1],
  });
}

const randomTime = random(3, 5);
let randomX, randomY;
let startX, startY;

export default ($el, offset = 1, x, y) => {
  startX = startX;
  startY = startY;

  randomX = random(10 * offset, 20 * offset);
  randomY = random(20 * offset, 30 * offset);

  moveX($el, 1);
  moveY($el, -1);
};
